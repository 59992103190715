export enum IconsEnum {
  TABLET_WITH_SPEAKER = 'tabletWithSpeaker',
  PEOPLE_THINKING = 'peopleThinkingIcon',
  GRAPH_GROWING_ICON = 'graphGrowingIcon',
  IDEA_ICON = 'ideaIcon',
  FACEBOOK = 'facebookIcon',
  INSTAGRAM = 'instagramIcon',
  TWITTER = 'twitterIcon',
  LINKEDIN = 'linkedinIcon',
  PDF = 'pdf',
  PPT = 'ppt',
  EXCEL = 'excel',
  WORD = 'word',
  LINK = 'link',
  VIDEO = 'video',
  UNKNOWN = 'unknown',
  CLOCK = 'clockIcon',
  FILE = 'fileIcon',
  CALENDAR = 'calendarIcon',
  VOD = 'vodIcon',
  AUDIO = 'audioIcon',
  WEBINAR = 'webinarIcon',
  LIVE = 'liveIcon',
}
