import { IconsEnum } from '@shared/enums/layout/icons.enum'
import dynamic from 'next/dynamic'
import { createElement, ReactElement } from 'react'

/**
 * Adding lazy loading icons.
 * This is a performance improvement to reduce the initial component load.
 */
const TabletWithSpeakerIcon = dynamic(() => import('@features/_ui/icons/tablet-with-speaker'), {
  loading: () => <></>,
})
const PeopleThinkingIcon = dynamic(() => import('@features/_ui/icons/people-thinking'), {
  loading: () => <></>,
})

const GraphGrowingIcon = dynamic(() => import('@features/_ui/icons/graph-growing'), {
  loading: () => <></>,
})

const IdeaIcon = dynamic(() => import('@features/_ui/icons/idea'), {
  loading: () => <></>,
})

const FacebookIcon = dynamic(() => import('@features/_ui/icons/facebook'), {
  loading: () => <></>,
})

const InstagramIcon = dynamic(() => import('@features/_ui/icons/instagram'), {
  loading: () => <></>,
})

const TwitterIcon = dynamic(() => import('@features/_ui/icons/twitter'), {
  loading: () => <></>,
})

const LinkedinIcon = dynamic(() => import('@features/_ui/icons/linkedin'), {
  loading: () => <></>,
})

const PdfIcon = dynamic(() => import('@ui/icons/pdf-v2'), {
  loading: () => <></>,
})

const WordIcon = dynamic(() => import('@ui/icons/word'), {
  loading: () => <></>,
})

const ExcelIcon = dynamic(() => import('@ui/icons/excel'), {
  loading: () => <></>,
})

const PptIcon = dynamic(() => import('@ui/icons/ppt'), {
  loading: () => <></>,
})

const LinkIcon = dynamic(() => import('@ui/icons/link'), {
  loading: () => <></>,
})

const UnknownFileIcon = dynamic(() => import('@ui/icons/unknown-file'), {
  loading: () => <></>,
})

const VideoPlayerIcon = dynamic(() => import('@ui/icons/video-player'), {
  loading: () => <></>,
})

const ClockIcon = dynamic(() => import('@ui/icons/clock'), {
  loading: () => <></>,
})

const FileIcon = dynamic(() => import('@ui/icons/file'), {
  loading: () => <></>,
})

const CalendarIcon = dynamic(() => import('@ui/icons/calendar'), {
  loading: () => <></>,
})

const AudioIcon = dynamic(() => import('@ui/icons/audio'), {
  loading: () => <></>,
})

const VodIcon = dynamic(() => import('@ui/icons/vod'), {
  loading: () => <></>,
})

const WebinarIcon = dynamic(() => import('@ui/icons/webinar'), {
  loading: () => <></>,
})

const LiveIcon = dynamic(() => import('@ui/icons/live'), {
  loading: () => <></>,
})

const IconComponents = {
  [IconsEnum.TABLET_WITH_SPEAKER]: TabletWithSpeakerIcon,
  [IconsEnum.PEOPLE_THINKING]: PeopleThinkingIcon,
  [IconsEnum.GRAPH_GROWING_ICON]: GraphGrowingIcon,
  [IconsEnum.IDEA_ICON]: IdeaIcon,
  [IconsEnum.FACEBOOK]: FacebookIcon,
  [IconsEnum.INSTAGRAM]: InstagramIcon,
  [IconsEnum.TWITTER]: TwitterIcon,
  [IconsEnum.LINKEDIN]: LinkedinIcon,
  [IconsEnum.WORD]: WordIcon,
  [IconsEnum.EXCEL]: ExcelIcon,
  [IconsEnum.PPT]: PptIcon,
  [IconsEnum.EXCEL]: ExcelIcon,
  [IconsEnum.PDF]: PdfIcon,
  [IconsEnum.LINK]: LinkIcon,
  [IconsEnum.VIDEO]: VideoPlayerIcon,
  [IconsEnum.UNKNOWN]: UnknownFileIcon,
  [IconsEnum.CLOCK]: ClockIcon,
  [IconsEnum.FILE]: FileIcon,
  [IconsEnum.CALENDAR]: CalendarIcon,
  [IconsEnum.AUDIO]: AudioIcon,
  [IconsEnum.WEBINAR]: WebinarIcon,
  [IconsEnum.VOD]: VodIcon,
  [IconsEnum.LIVE]: LiveIcon,
}

export interface Props {
  icon: IconsEnum
}

export default function IconsRender({ icon }: Props): ReactElement {
  if (typeof IconComponents[icon] !== 'undefined') {
    return createElement(IconComponents[icon])
  }
  return <p style={{ color: 'red' }}>Icon {icon} not defined.</p>
}
